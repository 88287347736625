import React from "react";
import "./style.css";
const TermsCondition = () => {
  return (
    <React.Fragment>
      <div className="container">
        <div className="content">
          <p style={{ display: "block" }}>
            We are still working on Public TermsCondition Us page!
          </p>
          <br />
          <div className="lds-ripple">
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default TermsCondition;
