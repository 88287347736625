import { Button, Divider, Box, Typography, styled, Card } from "@mui/material";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Countdown from "react-countdown";
import { Link } from "react-router-dom";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const Component = styled(Box)`
  margin-top: 10px;
  background: #ffffff;
`;

const Deal = styled(Box)`
  display: flex;
  padding: 15px 20px;
`;

const DealText = styled(Typography)`
  font-size: 22px;
  font-weight: 600;
  line-height: 32px;
  margin-right: 25px;
`;

const Timer = styled(Box)`
  color: #7f7f7f;
  margin-left: 10px;
  display: flex;
  align-items: center;
`;

const ViewAllButton = styled(Button)`
  margin-left: auto;
  background-color: #2874f0;
  border-radius: 2px;
  font-size: 13px;
`;

const Image = styled("img")({
  width: "auto",
  height: 150,
  transition: "transform 0.3s",
});

const Text = styled(Typography)`
  font-size: 14px;
  margin-top: 5px;
`;

const RenderTimer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));

// Custom shadow, hover, and click effect
const CustomCard = styled(Card)`
  margin: 1px;
  height: 95%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  cursor: pointer;

  &:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    transform: translateY(-5px);

    img {
      transform: scale(1.05);
    }
  }

  &:active {
    transform: scale(0.95);
    transition: transform 0.2s ease;
  }
`;

const MultiSlide = ({ data, timer, title }) => {
  const timerURL =
    "https://static-assets-web.flixcart.com/www/linchpin/fk-cp-zion/img/timer_a73398.svg";

  const renderer = ({ hours, minutes, seconds }) => {
    return (
      <RenderTimer variant="span">
        {hours} : {minutes} : {seconds} Left
      </RenderTimer>
    );
  };

  const handleClick = (id) => {
    console.log("Card clicked:", id);
    // Custom click logic can be added here
  };

  return (
    <Component>
      <Deal>
        <DealText>{title}</DealText>
        {timer && (
          <Timer>
            <img src={timerURL} style={{ width: 24 }} alt="time clock" />
            <Countdown date={Date.now() + 5.04e7} renderer={renderer} />
          </Timer>
        )}
        <ViewAllButton variant="contained" color="primary">
          View All
        </ViewAllButton>
      </Deal>
      <Divider />
      <Carousel
        swipeable={false}
        draggable={false}
        responsive={responsive}
        centerMode={true}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={10000}
        keyBoardControl={true}
        showDots={false}
        containerClass="carousel-container"
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
      >
        {data.map((temp, index) => (
          <CustomCard
            sx={{ m: 1, height: "95%" }}
            onClick={() => handleClick(temp.id)}
            key={index}
          >
            <Link to={`product/${temp.id}`} style={{ textDecoration: "none" }}>
              <Box textAlign="center" style={{ padding: "25px 15px" }}>
                <Image src={temp.url} />
                <Text style={{ fontWeight: 600, color: "#212121" }}>
                  {temp.title.shortTitle}
                </Text>
                <Text style={{ color: "green" }}>{temp.discount}</Text>
                <Text style={{ color: "#212121", opacity: ".6" }}>
                  {temp.tagline}
                </Text>
              </Box>
            </Link>
          </CustomCard>
        ))}
      </Carousel>
    </Component>
  );
};

const Slide = (props) => {
  return <>{props.multi === true && <MultiSlide {...props} />}</>;
};

export default Slide;
