import { Grid, Box, styled } from "@mui/material";
import Slide from "./Slide";

const RightComponent = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(1),
  backgroundColor: "#FFFFFF",
  padding: theme.spacing(1),
  textAlign: "center",
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

const AdImage = styled("img")(({ theme }) => ({
  width: "100%",
  [theme.breakpoints.down("lg")]: {
    width: "100%",
  },
}));

const MidSlide = ({ products }) => {
  const adURL =
    "https://rukminim1.flixcart.com/flap/464/708/image/633789f7def60050.jpg?q=70";

  return (
    <Grid container spacing={2}>
      <Grid item lg={10} sm={10} xs={12}>
        <Slide
          data={products}
          title="Deals of the Day"
          timer={true}
          multi={true}
        />
      </Grid>

      <Grid item lg={2} sm={2} xs={12}>
        <RightComponent>
          <AdImage src={adURL} alt="Advertisement" />
        </RightComponent>
      </Grid>
    </Grid>
  );
};

export default MidSlide;
