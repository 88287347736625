import { useEffect } from "react";

import { Box, Typography, Button, Grid, styled } from "@mui/material";
import { useParams } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import { addToCart, removeFromCart } from "../../redux/actions/cartActions";

import TotalView from "./TotalView";
import EmptyCart from "./EmptyCart";
import CartItem from "./CartItem";

import { post } from "../../utils/paytm";
import { payUsingPaytm } from "../../service/api";

const Component = styled(Grid)(({ theme }) => ({
  padding: "30px 135px",
  display: "flex",
  [theme.breakpoints.down("sm")]: {
    padding: "15px 0",
  },
}));

const LeftComponent = styled(Grid)(({ theme }) => ({
  paddingRight: 15,
  [theme.breakpoints.down("sm")]: {
    marginBottom: 15,
  },
}));

const Header = styled(Box)`
  padding: 15px 24px;
  background: #fff;
`;

const BottomWrapper = styled(Box)`
  padding: 16px 22px;
  background: #fff;
  box-shadow: 0 -2px 10px 0 rgb(0 0 0 / 10%);
  border-top: 1px solid #f0f0f0;
`;

const StyledButton = styled(Button)`
  display: flex;
  margin-left: auto;
  background: #fb641b;
  color: #fff;
  border-radius: 2px;
  width: 250px;
  height: 51px;
`;

const Cart = () => {
  const cartDetails = useSelector((state) => state.cart);
  // const { cartItems } = cartDetails;
  const cartItems = [
    {
      id: 1,
      url: "https://dummyimage.com/110",
      title: {
        longTitle: "Apple iPhone 13 (128GB) - Midnight",
      },
      price: {
        cost: 69999,
        mrp: 79999,
        discount: "12%",
      },
    },
    {
      id: 2,
      url: "https://dummyimage.com/110",
      title: {
        longTitle: "Sony WH-1000XM4 Wireless Noise Cancelling Headphones",
      },
      price: {
        cost: 24999,
        mrp: 29999,
        discount: "17%",
      },
    },
    {
      id: 3,
      url: "https://dummyimage.com/110",
      title: {
        longTitle: "Apple MacBook Air (M1, 2020)",
      },
      price: {
        cost: 89999,
        mrp: 99999,
        discount: "10%",
      },
    },
  ];

  const { id } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    if (cartItems && id !== cartItems.id) dispatch(addToCart(id));
  }, [dispatch, cartItems, id]);

  const removeItemFromCart = (id) => {
    dispatch(removeFromCart(id));
  };

  const buyNow = async () => {
    let response = await payUsingPaytm({
      amount: 500,
      email: "kunaltyagi@gmail.com",
    });
    var information = {
      action: "https://securegw-stage.paytm.in/order/process",
      params: response,
    };
    post(information);
  };

  return (
    <>
      {cartItems.length ? (
        <Component container>
          <LeftComponent item lg={9} md={9} sm={12} xs={12}>
            <Header>
              <Typography style={{ fontWeight: 600, fontSize: 18 }}>
                My Cart ({cartItems?.length})
              </Typography>
            </Header>
            {cartItems.map((item, index) => (
              <CartItem
                item={item}
                removeItemFromCart={removeItemFromCart}
                key={index}
              />
            ))}
            <BottomWrapper>
              <StyledButton onClick={() => buyNow()} variant="contained">
                Place Order
              </StyledButton>
            </BottomWrapper>
          </LeftComponent>
          <Grid item lg={3} md={3} sm={12} xs={12}>
            <TotalView cartItems={cartItems} />
          </Grid>
        </Component>
      ) : (
        <EmptyCart />
      )}
    </>
  );
};

export default Cart;
